import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { devUrl } from 'src/app/global/global';
import { Configuration } from 'src/app/global/global-config';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  constructor(
    private http: HttpClient, private conf: Configuration
  ) {}


  /**
   * @description method callin api to change post status
   * @author Jagannath
   * @date 2020-10-15
   * @param data payload
   */
  changePostStatus(data:any): Observable<any> {
    return this.http.request('delete', `${devUrl}assets/`, {body: data})
  }

  /**
   * @description calling get all post api
   * @author Jagannath
   * @date 2020-11-11
   * @param list query
   */
  getPosts(list): Observable<any>{
    let params = new HttpParams()
      .set('page', list.page)
      .set('status', list.status)
    if(list.searchText){
      params = params.set('search', list.searchText)
    }
    if(list.sortBy){
      params = params.set('sortBy', list.sortBy)

    }
    if(list.postType){
      params = params.set('postType', list.postType)

    }
    if(list.fromDate && list.toDate){
      list.fromDate = new Date(list.fromDate).setUTCHours(0,0,0,0);
      list.toDate = new Date(list.toDate).setUTCHours(23,59,59,999);
      params = params.set('fromDate', list.fromDate).set('toDate', list.toDate)
    }
    return this.http.get(`${devUrl}user/posts/`, {params})
  }

  /**
   * @description this method take likes list from database
   * @author Bhavesh Jain
   * @date 2020-02-20
   * @param list  query
   * @param reportType String - eg. likes/comments etc
   */
  getPostReports(list, reportType) {
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('skip', list.skip)
      .set('assetId', list.postId)
    if(list.searchText){
      params = params.set('searchText', list.searchText)
    }
    if(list.fromDate){
      params = params.set('fromDate', list.fromDate)
    }
    if(list.toDate){
      params = params.set('toDate', list.toDate)
    }
    console.log(list,"list")
    switch (reportType){
      case 'likes':
        return this.http.get(`${devUrl}likesDislikeAsset/`, {params});
      case 'comments':
        params = new HttpParams()
        .set('limit', list.limit)
        .set('skip', list.skip)
        .set('assetId', list.postId)
        .set('status', list.status)
        if(list.fromDate){
          params = params.set('fromDate', list.fromDate)
        }
        if(list.toDate){
          params = params.set('toDate', list.toDate)
        }
        return this.http.get(`${devUrl}comments/`, {params});
      case 'purchase':
        params = new HttpParams()
      .set('limit', list.limit)
      .set('skip', list.skip)
      .set('postId', list.postId)
      if(list.fromDate){
        params = params.set('fromDate', list.fromDate)
      }
      if(list.toDate){
        params = params.set('toDate', list.toDate)
      }
        return this.http.get(`${devUrl}purchaseExclusivePost`, {params});
      case 'tips-received':
        params = params.delete('assetId').set('id', list.postId);
        return this.http.get(`${devUrl}tipCreator`, {params});
      case 'post-shared':
        return this.http.get(`${devUrl}shared/`, { params });
      default:
        return this.http.get(`${devUrl}assets/report/`, {params});
    }
  }

  handleIsNsfwPost(data: any = {}): Observable<any>{
    return this.http.patch(`${devUrl}change-nsfw-status/`, data)
  }

  handleIsSchedulePost(data: any = {}): Observable<any>{
    return this.http.post(`${devUrl}change-status/`, data)
  }

  getPostSharedCount(list, reportType) {
      let params = new HttpParams()
      .set('postId', list.postId)
        return this.http.get(`${devUrl}shared/`, { params });
  }
   /**
   * @description get api to get all reported models
   * @author Anjali Patil
   * @date 2021-04-09
   * @param list query (userType,type, limit, offset, searchText)
   */
    getReportedModels(list): Observable<any> {
      let params = new HttpParams()
        .set('userType', list.userType)
        .set('type', list.type)
        .set('limit', list.limit)
        .set('offset', list.skip);
      if (list.searchText) params = params.set('searchText', list.searchText);
      if(list.fromDate){
        params = params.set('fromDate', list.fromDate)
      }
      if(list.toDate){
        params = params.set('toDate', list.toDate)
      }
      return this.http.get(`${devUrl}report`, { params });
    }


  /**
   * @description api method to get all views of a post
   * @author Jagannath
   * @date 2020-11-26
   * @param list query
   */
  getPostViewsCount(list): Observable<any>{
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('set', list.set)
      .set('assetId', list.postId);
      if(list.fromDate){
        params = params.set('fromDate', list.fromDate)
      }
      if(list.toDate){
        params = params.set('toDate', list.toDate)
      }
      return this.http.get(`${devUrl}post/postImpressions/`, {params});
  }
  getAwsCognitoAppConfigApi():Observable<any>{
    let headers = new HttpHeaders();
    const token: string = this.conf.getItem('token');
    headers = headers.append('authorization', token);
    return this.http.get(`${devUrl}appConfig`,{ headers })
  }

  getPostViewLogs(list): Observable<any>{
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('skip', list.skip)
      .set('viewId', list.viewId)
      if(list.searchText){
        params = params.set('searchText', list.searchText)
      }
      if(list.fromDate){
        params = params.set('fromDate', list.fromDate)
      }
      if(list.toDate){
        params = params.set('toDate', list.toDate)
      }
      return this.http.get(`${devUrl}assets/views/`, {params});
  }

  postReportCount(id: String): Observable<any>{
    return this.http.get<any>(`${devUrl}assetCounts/?assetId=${id}`);
  }

  getPostReporterList(list): Observable<any>{
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('skip', list.skip)
      .set('reportedId', list.reportedId)
    return this.http.get(`${devUrl}report/reporters`, {params});
  }
  uploadVideoAwsCognito(): Observable<any> {
    const headers = new HttpHeaders()
    .set('authorization', localStorage.getItem('token'))
    .set('lan', localStorage.getItem('lan'))
    return this.http.get(`${devUrl}cognitoToken`, { headers })
  }

  deleteCommentApi(data):Observable<any>{
    return this.http.request('delete',`${devUrl}comments/`,{ body: data } )
  }
}


