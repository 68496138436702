import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { devUrl } from 'src/app/global/global';

@Injectable({
  providedIn: 'root'
})
export class ShoutoutService {

  constructor(private http: HttpClient) { }

   /**
   * @description method calling get api to get shoutout 
   * @author Anjali Patil
   * @date 2021-06-6
   * @param list query
   */
  getshoutoutApi(list):Observable<any>{
    let params = new HttpParams()
      .set('status',list.status)
      .set('limit', list.limit)
      .set('offset', list.skip);
    if (list.searchText) params = params.set('searchText', list.searchText);


    return this.http.get(`${devUrl}virtualOrder/admin`, { params })
  }

   /**
     * @description changing status of request (ACCEPTED, REJECTED, COMPLETED, CANCELLED)
      * @author Anjali Patil
      * @date 2021-06-07
      * @param data payload data
      * @returns success message
     */
    changeStatusHandler(data):Observable<any> {
        return this.http.patch(`${devUrl}virtualOrder/admin`, data)
    }

     /**
     * @description Get order Details of product
      * @author Anjali Patil
      * @date 2021-07-22
      * @param id order Id
      * @returns success message with response data
     */
    viewOffer(id):Observable<any> {
      console.log(id , ":::::::::::::::::::::::::::::::::::::::::")
      return this.http.get(`${devUrl}virtualOrder/details?virtualOrderId=`+ id)
  }

}
