import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UsersService } from 'src/app/pages/ui-features/service/users.service';

import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';

@Component({
  selector: 'app-video-loading',
  templateUrl: './video-loading.component.html',
  styleUrls: ['./video-loading.component.scss']
})
export class VideoLoadingComponentDialog implements OnInit {

  @Input() file: any;
  @Input() title: string;
  @Input() content: string;
  @Input() ok: string;
  @Input() cancelT: string;
  @Input() folderName: string;

  fileUploadingData: any;
  fileUploadProgress = 0;
  showButton = false


  constructor(
    protected ref: NbDialogRef<VideoLoadingComponentDialog>,
    private uploadService: UsersService
  ) { }

  ngOnInit() {
    this.videoupload()
  }

  videoupload(){
    const data = this.file.target.files;
    const file = data.item(0)
    const contentType = file.type;
    this.uploadService.uploadVideoAwsCognito().subscribe(
        (res)=> {
          if(res.message == "Success"){
              AWS.config.update({
                  region: res.data.region ,
                  credentials: new AWS.CognitoIdentityCredentials({
                    IdentityId: res.data.IdentityId,
                    Logins: {
                      'cognito-identity.amazonaws.com': res.data.Token
                    }
                  })
                })
              const bucket = new S3({ signatureVersion: "v4" });
                const params = {
                    Bucket: res.data.videoBucket,
                    Key: this.folderName + '/' + file.name,
                    Body: file,
                    ACL: 'public-read',
                    ContentType: contentType
                };
                console.log(params,"params")
                const options = { partSize: 10*1024*1024, queueSize: 10 };
                this.fileUploadingData = bucket.upload(params, options, function (err, data) {
                    if (!err) {
                      return this.fileUploadingData = data
                    } else {
                      console.log('There was an error uploading your file: ', err);
                    }
                })
                this.fileUploadingData.on('httpUploadProgress', (e)=> {
                  this.fileUploadProgress = Math.round((100 /e.total) * e.loaded);
                })
                this.fileUploadingData.send((err, response) => {
                  this.ref.close(response)
               });
          }
      },
      error => {
        console.log(error);
      }
    )
  }


  cancel(){
    this.ref.close()
  }

  submit(){

    console.log('this.showButton: 2', this.showButton);
    // console.log(typeof this.fileUploadingData, "ghkkkkkkkkkjghgkjgjgj")
    // console.log(this.fileUploadingData, "ghkkkkkkkkkjghgkjgjgj")
    // console.log(this.fileUploadingData.fileUploadingData)
    // this.ref.close(this.fileUploadingData.fileUploadingData)
  }

}
