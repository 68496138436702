import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { devUrl } from 'src/app/global/global';

@Injectable({
  providedIn: 'root'
})
export class TipLogsService {

  constructor(
    private http: HttpClient
  ) { }


  /**
   * @description method calling get api to get all tiplogs
   * @author Jagannath
   * @date 2020-10-15
   * @param list query
   */
  getTipsLogs(list):Observable<any>{
    let params = new HttpParams()
      .set('limit', list.limit)
      .set('offset', list.skip)
    if(list.searchText) params = params.set('searchText', list.searchText);

    return this.http.get(`${devUrl}tipCreator/log`, { params })
  }

  getTipsReport(list):Observable<any>{
    const headers = new HttpHeaders()
      .set('authorization', localStorage.getItem('token'))
      .set('lan', localStorage.getItem('lan'))
    let params = new HttpParams()
    .set('limit', list.limit)
    .set('skip', list.skip)
    .set('id', list.postId)
    if(list.searchText) params = params.set('searchText', list.searchText);

    return this.http.get(`${devUrl}tipCreator`, {params, headers});
  }
}
