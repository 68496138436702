import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Configuration } from '.././global/global-config';

@Injectable()
export class InnerAuthGuard implements CanActivate {
  constructor(private router: Router, private conf: Configuration) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(!localStorage.getItem('access'))  this.router.navigate(['/login'])
    const accessList = JSON.parse(localStorage.getItem('access'));
    if(!accessList || Object.keys(accessList).length === 0)  this.router.navigate(['/login'])
    switch (state.url.split('/')[2]) {
        case 'text-settings':
          if (accessList.AppTextSettings.slice(0, 1) != '1') {
            this.router.navigate(['/pages/admin-users']);
            return false;
          }
        break;
        case 'admin-users':
          if (accessList.AdminUsers.slice(0, 1) != '1') {
            this.router.navigate(['/pages/creator-banner']);
            return false;
          }
        break;
        case 'creator-banner':
          if (accessList.Banner.slice(0, 1) != '1') {
            this.router.navigate(['/pages/bulk-message-logs']);
            return false;
          }
        break;
        case 'bulk-message-logs':
          if (accessList.BulkMessageLogs.slice(0, 1) != '1') {
            this.router.navigate(['/pages/bulk-message-logs']);
            return false;
          }
        break;
        case 'creators':
          if (accessList.Creators.slice(0, 1) != '1') {
            this.router.navigate(['/pages/creator-group']);
            return false;
          }
        break;
        case 'creator-group':
          if (accessList.CreatorsGroup.slice(0, 1) != '1') {
            this.router.navigate(['/pages/dashboard']);
            return false;
          }
        break;
        case 'dashboard':
          if (accessList.Dashboard.slice(0, 1) != '1') {
            this.router.navigate(['/pages/exclusive-purchase']);
            return false;
          }
        break;
        case 'exclusive-purchase':
          if (accessList.ExclusivePurchase.slice(0, 1) != '1') {
            this.router.navigate(['/pages/featured-creators']);
            return false;
          }
        break;
        case 'featured-creators':
          if (accessList.FeaturedCreators.slice(0, 1) != '1') {
            this.router.navigate(['/pages/hash-tag']);
            return false;
          }
        break;
        case 'hash-tag':
          if (accessList.HashTag.slice(0, 1) != '1') {
            this.router.navigate(['/pages/category']);
            return false;
          }
        break;
        case 'category':
          if (accessList.HomePageBuilder.slice(0, 1) != '1') {
            this.router.navigate(['/pages/id-document-types']);
            return false;
          }
        break;
        case 'id-document-types':
          if (accessList.IdDocumentTypes.slice(0, 1) != '1') {
            this.router.navigate(['/pages/live-stream']);
            return false;
          }
        break;
        case 'live-stream':
          if (accessList.LiveStream.slice(0, 1) != '1') {
            this.router.navigate(['/pages/locked-posts']);
            return false;
          }
        break;
        case 'locked-posts':
          if (accessList.LockedPosts.slice(0, 1) != '1') {
            this.router.navigate(['/pages/live-stream']);
            return false;
          }
        break;
        case 'posts':
          if (accessList.Posts.slice(0, 1) != '1') {
            this.router.navigate(['/pages/referral-earning-logs']);
            return false;
          }
        break;
        case 'referral-earning-logs':
          if (accessList.ReferralEarningLogs.slice(0, 1) != '1') {
            this.router.navigate(['/pages/review-ratings']);
            return false;
          }
        break;
        case 'review-ratings':
          if (accessList.ReviewAndRatings.slice(0, 1) != '1') {
            this.router.navigate(['/pages/send-update']);
            return false;
          }
        break;
        case 'send-update':
          if (accessList.SendUpdate.slice(0, 1) != '1') {
            this.router.navigate(['/pages/stories']);
            return false;
          }
        break;
        case 'stories':
          if (accessList.Stories.slice(0, 1) != '1') {
            this.router.navigate(['/pages/attributes']);
            return false;
          }
        break;
        case 'attributes':
          if (accessList.ShoutOutAttributes.slice(0, 1) != '1') {
            this.router.navigate(['/pages/promotion-plan']);
            return false;
          }
        break;
        case 'promotion-plan':
          if (accessList.SubscriptionPlans.slice(0, 1) != '1') {
            this.router.navigate(['/pages/tip-logs']);
            return false;
          }
        break;
        case 'tip-logs':
          if (accessList.TipLogs.slice(0, 1) != '1') {
            this.router.navigate(['/pages/users']);
            return false;
          }
        break;
        case 'users':
          if (accessList.Users.slice(0, 1) != '1') {
            this.router.navigate(['/pages/vod-category']);
            return false;
          }
        break;
        case 'vod-category':
          if (accessList.VODCategory.slice(0, 1) != '1') {
            this.router.navigate(['/pages/message-plans']);
            return false;
          }
        break;
        case 'message-plans':
          if (accessList.VIPMessagePlans.slice(0, 1) != '1') {
            this.router.navigate(['/pages/shoutout']);
            return false;
          }
        break;
        case 'shoutout':
          if (accessList.VirtualProductOrder.slice(0, 1) != '1') {
            this.router.navigate(['/pages/seo-setting']);
            return false;
          }
        break;
        case 'seo-setting':
          if (accessList.SeoSetting.slice(0, 1) != '1') {
            this.router.navigate(['/pages/vod-home-page-builder']);
            return false;
          }
        break;
        case 'vod-home-page-builder':
          if (accessList.VODHomePageBuilder.slice(0, 1) != '1') {
            this.router.navigate(['/pages/vod-movies']);
            return false;
          }
        break;
        case 'vod-movies':
          if (accessList.VODMovies.slice(0, 1) != '1') {
            this.router.navigate(['/pages/vod-video-series']);
            return false;
          }
        break;
        case 'vod-video-series':
          if (accessList.VODVideoSeries.slice(0, 1) != '1') {
            this.router.navigate(['/pages/logs']);
            return false;
          }
        case 'logs':
          if (accessList.Logs.slice(0, 1) != '1') {
            this.router.navigate(['/login']);
            return false;
          }
          break;
    }

    // this.router.navigate(['']);
    return true;
  }
}
